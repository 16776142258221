@import url("https://fonts.googleapis.com/css2?family=Montaga&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bitter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Shelley Allegro BT";
  src: url("./fonts/shelley-allegro-bt.ttf");
}

h1 {
  @apply !font-shelley;
}
h5 {
  @apply !font-eb;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/* Slider  */
.slick-dots li button::before {
  font-size: 10px !important;
}
.gm-style .gm-style-iw-c {
  min-width: 250px !important;
}
.gm-ui-hover-effect {
  display: none !important;
}

.MuiAvatar-root .MuiAvatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .MuiAvatar-root .MuiAvatar-img {
    height: fit-content;
    object-fit: contain;
  }
}
